export function _hasProperty(obj, prop) {
    const _has = Object.prototype.hasOwnProperty

    return _has.call(obj, prop)
}

export function _isObjEmpty(obj) {
    return obj && Object.keys(obj).length === 0
}

function append(form, key, value) {
    if (Array.isArray(value)) {
        return Array.from(value.keys()).forEach(index => append(form, composeKey(key, index.toString()), value[index]))
    } else if (value instanceof Date) {
        return form.append(key, value.toISOString())
    } else if (value instanceof File) {
        return form.append(key, value, value.name)
    } else if (value instanceof Blob) {
        return form.append(key, value)
    } else if (typeof value === 'boolean') {
        return form.append(key, value ? '1' : '0')
    } else if (typeof value === 'string') {
        return form.append(key, value)
    } else if (typeof value === 'number') {
        return form.append(key, `${value}`)
    } else if (value === null || value === undefined) {
        return form.append(key, '')
    }

    _objectToFormData(value, form, key)
}

export function _objectToFormData(source, form = new FormData(), parentKey = null) {
    source = source || {}

    for (const key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
            append(form, composeKey(parentKey, key), source[key])
        }
    }

    return form
}

function composeKey(parent, key) {
    return parent ? parent + '[' + key + ']' : key
}

export function _themed(className, color) {
    return className.replaceAll('{color}', color)
}

export function findLastIndex(array, searchKey, searchValue) {
    var index = array
        .slice()
        .reverse()
        .findIndex(x => x[searchKey] === searchValue)
    var count = array.length - 1
    var finalIndex = index >= 0 ? count - index : index
    return finalIndex
}

export const formatDate = date =>
    new Intl.DateTimeFormat('en-US', {
        month: 'short',
        year: '2-digit',
    }).format(date)

export const sleep = time => new Promise(resolve => setTimeout(() => resolve(), time))

export function _summarize(str, max) {
    if (str.length <= max) return str
    const subString = str.substring(0, max - 1)
    return subString.substring(0, subString.lastIndexOf(' ')) + '...'
}



/**
 * Converting Bytes to Readable Human File Sizes.
 * @param {number} bytes Bytes in Number
 */
export const humanFileSize = bytes => {
    let BYTES = bytes
    const thresh = 1000

    if (Math.abs(BYTES) < thresh) {
        return `${BYTES} B`
    }

    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    let u = -1
    const r = 10 ** 1

    do {
        BYTES /= thresh
        u += 1
    } while (Math.round(Math.abs(BYTES) * r) / r >= thresh && u < units.length - 1)

    return `${BYTES.toFixed(1)} ${units[u]}`
}


export const isObjValEmpty = (obj) => {
    let isEmpty = false
    Object.values(obj).map(v => (!v && (isEmpty = true)))
    return isEmpty
}